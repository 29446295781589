'use client';
import React, { useState, useEffect, useMemo } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/lib/utils';
import { useCallback } from 'react';

type Direction = 'TOP' | 'LEFT' | 'BOTTOM' | 'RIGHT';

const highlight =
  'radial-gradient(75% 181.16% at 50% 50%, #7A1FBF 0%, rgba(255, 255, 255, 0) 100%)';

const rotateDirection = (
  currentDirection: Direction,
  clockwise: boolean,
): Direction => {
  const directions: Direction[] = ['TOP', 'LEFT', 'BOTTOM', 'RIGHT'];
  const currentIndex = directions.indexOf(currentDirection);
  const nextIndex = clockwise
    ? (currentIndex - 1 + directions.length) % directions.length
    : (currentIndex + 1) % directions.length;
  return directions[nextIndex];
};

const movingMap = {
  TOP: 'radial-gradient(20.7% 50% at 50% 0%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)',
  LEFT: 'radial-gradient(16.6% 43.1% at 0% 50%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)',
  BOTTOM:
    'radial-gradient(20.7% 50% at 50% 100%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)',
  RIGHT:
    'radial-gradient(16.2% 41.199999999999996% at 100% 50%, hsl(0, 0%, 100%) 0%, rgba(255, 255, 255, 0) 100%)',
};

export function HoverBorderGradient({
  children,
  containerClassName,
  className,
  as: Tag = 'button',
  duration = 1,
  clockwise = true,
  ...props
}: React.PropsWithChildren<
  {
    as?: React.ElementType;
    containerClassName?: string;
    className?: string;
    duration?: number;
    clockwise?: boolean;
  } & React.HTMLAttributes<HTMLElement>
>) {
  const [hovered, setHovered] = useState<boolean>(false);
  const [direction, setDirection] = useState<Direction>('TOP');

  const handleMouseEnter = useCallback(() => setHovered(true), []);
  const handleMouseLeave = useCallback(() => setHovered(false), []);

  useEffect(() => {
    if (!hovered) {
      const interval = setInterval(() => {
        setDirection((prevState) => rotateDirection(prevState, clockwise));
      }, duration * 1000);
      return () => clearInterval(interval);
    }
  }, [hovered, duration, clockwise]);

  const initial = useMemo(
    () => ({ background: movingMap[direction] }),
    [direction],
  );

  const animate = useMemo(
    () => ({
      background: hovered
        ? [movingMap[direction], highlight]
        : movingMap[direction],
    }),
    [hovered, direction],
  );

  const transition = useMemo(() => ({ ease: 'linear', duration }), [duration]);

  const staticStyle = useMemo(
    () => ({
      filter: 'blur(2px)',
      position: 'absolute' as const,
      width: '100%',
      height: '100%',
    }),
    [],
  ); // Add dependencies if staticStyle depends on any props or state

  return (
    <Tag
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn(
        'relative flex rounded-full border content-center bg-black/20 hover:bg-black/10 transition duration-500 dark:bg-white/20 items-center flex-col flex-nowrap gap-10 h-min justify-center overflow-visible p-px decoration-clone w-fit',
        containerClassName,
      )}
      {...props}
    >
      <div
        className={cn(
          'w-auto text-white z-10 bg-black px-4 py-2 rounded-[inherit]',
          className,
        )}
      >
        {children}
      </div>
      <motion.div
        className="flex-none inset-0 overflow-hidden absolute z-0 rounded-[inherit]"
        style={staticStyle}
        initial={initial}
        animate={animate}
        transition={transition}
      />
      <div className="bg-black absolute z-1 flex-none inset-[2px] rounded-[100px]" />
    </Tag>
  );
}
