'use client';
import React from 'react';
import { useMemo } from 'react';
import { HoverBorderGradient } from '@/components/ui/aceternity/hover-border-gradient';
import { cn } from '@/lib/utils';
import Link from 'next/link';

export function CallToAction({
  className,
  text,
  href,
  icon,
  containerClassName,
  fullWidth = false,
}: {
  className?: string;
  text?: string;
  href?: string | null;
  icon?: React.ReactNode;
  containerClassName?: string;
  fullWidth?: boolean;
}) {
  const content = useMemo(
    () => (
      <HoverBorderGradient
        containerClassName={cn(
          'rounded-xl',
          fullWidth ? 'w-full' : '',
          containerClassName,
        )}
        as="button"
        className={cn(
          'dark:bg-black bg-white text-black dark:text-white flex items-center space-x-2',
          fullWidth ? 'w-full justify-center px-4 py-2' : '',
        )}
      >
        <span className="whitespace-nowrap">{text || 'Contactez-Nous'}</span>
        {icon || null}
      </HoverBorderGradient>
    ),
    [containerClassName, fullWidth, icon, text],
  );

  const memoizedContent = useMemo(
    () =>
      href ? (
        <Link href={href} className={cn(fullWidth ? 'w-full' : '')}>
          {content}
        </Link>
      ) : (
        content
      ),
    [content, href, fullWidth],
  );

  return (
    <div
      className={cn(
        'flex justify-center text-center',
        fullWidth ? 'w-full' : '',
        className,
      )}
    >
      {memoizedContent}
    </div>
  );
}
